import { FC, Fragment, ReactElement } from 'react'
import dynamic from 'next/dynamic'
import { MaintenanceProvider } from 'components/Banner/hooks/useMaintenance'
import Secure from 'components/Secure'
import StoreGlobalDispatch from 'Shared'
import { isG1 } from 'utils/envUtils'
import { isClientPortal } from 'utils/isClientPortal'
import { AppContent } from '../AppContent'

type Props = {
  appHeader: ReactElement
  page: ReactElement
}

const VisitTracker = dynamic(() => import('./components/VisitLogger'), {
  ssr: false,
})

export const SecuredContent: FC<Props> = ({ appHeader, page }) => {
  const Wrapper = isG1() ? MaintenanceProvider : Fragment
  return (
    <Wrapper>
      {!isClientPortal && <VisitTracker />}
      <StoreGlobalDispatch />
      <Secure>
        <AppContent appHeader={appHeader} page={page} />
      </Secure>
    </Wrapper>
  )
}
