import { Children, FC, PropsWithChildren, useState } from 'react'
import { ChevronDownIcon, Menu, Typography } from '@g1/components'
import clsx from 'classnames'
import { HeaderButton } from '../HeaderLink'

import Styles from './TopMenuDropdown.module.scss'

type TopMenuDropdownProps = PropsWithChildren & {
  active?: boolean
  onClick?: () => void
  text?: string
}

export const TopMenuDropdown: FC<TopMenuDropdownProps> = ({
  children,
  active = false,
  onClick,
  text,
}) => {
  const [accountsMenuAnchorEl, setAccountsMenuAnchorEl] =
    useState<null | HTMLElement>(null)
  if (Children.count(children) === 1) {
    return (
      <HeaderButton onClick={onClick} active={active}>
        {text}
      </HeaderButton>
    )
  } else {
    return (
      <>
        <div
          className={clsx(Styles.accountsTabItem, {
            [Styles.active]: active,
          })}
        >
          <a
            className={clsx(Styles.accountsNavItem)}
            onClick={ev => setAccountsMenuAnchorEl(ev.currentTarget)}
          >
            <Typography variant="body2">{text}</Typography>
            <ChevronDownIcon />
          </a>
        </div>
        <Menu
          className={Styles.dropdownMenu}
          anchorEl={accountsMenuAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={() => setAccountsMenuAnchorEl(null)}
          open={Boolean(accountsMenuAnchorEl)}
          onClick={() => setAccountsMenuAnchorEl(null)}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          slotProps={{
            paper: {
              className: Styles.accountsMenu,
            },
          }}
        >
          {children}
        </Menu>
      </>
    )
  }
}
