import { FC, memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { GalaxyOneProvider } from '@g1/components'
import { buildMarginCallWidget } from '@galaxy-digital/lending'
import useAuthUser from 'hooks/useAuthUser'
import { useLendingMarginNavigate } from 'hooks/useLendingMarginNavigate'
import { AccountInfo } from 'redux/models/GalaxyUser'
import { getSelectedCounterparty } from 'redux/selectors/selectGalaxyUser'
import { selectOktaToken } from 'redux/selectors/selectOktaToken'
import { isLendingAccount, isMarginTradingAccount } from 'utils/accounts'

const envVars = {
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  lendingServiceUrl: process.env.NEXT_PUBLIC_LENDING_SERVICE_BASE_URL,
  balancesServiceUrl: process.env.NEXT_PUBLIC_G1_BALANCES_URL,
  transactionUrl: process.env.NEXT_PUBLIC_G1_TRANSACTIONS_URL,
  transferUrl: process.env.NEXT_PUBLIC_TIMS_URL,
}
const MarginCallBannerAndAlert = buildMarginCallWidget(envVars)

interface AccountInfoWithIndex extends AccountInfo {
  accountIndex: number
}

const LendingBanner: FC = () => {
  const accountInfo = useSelector(getSelectedCounterparty)?.accountInfo
  const oktaToken = useSelector(selectOktaToken)
  const user = useAuthUser('g1')
  const galaxyNavigate = useLendingMarginNavigate()

  const lendingAccounts = useMemo(() => {
    return accountInfo.reduce<AccountInfoWithIndex[]>((acc, account, index) => {
      if (isLendingAccount(account) || isMarginTradingAccount(account)) {
        acc.push({ ...account, accountIndex: index })
      }
      return acc
    }, [])
  }, [accountInfo])

  if (lendingAccounts.length > 0) {
    return (
      <GalaxyOneProvider theme="dark">
        <MarginCallBannerAndAlert
          user={user}
          galaxyNavigate={galaxyNavigate}
          accounts={lendingAccounts}
          oktaToken={oktaToken}
          account={lendingAccounts[0]}
          accountIndex={0}
        />
      </GalaxyOneProvider>
    )
  }
  return null
}

export default memo(LendingBanner)
