import { FC, PropsWithChildren } from 'react'
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import errorToast from 'utils/errorToast'
import sentry from 'utils/sentry'

const { captureException } = sentry()

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: Error, { queryKey }) => {
      captureException(error, { errorInfo: { queryKey } })
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables, context, { options }) => {
      captureException(error, {
        errorInfo: { mutationKey: options.mutationKey },
      })
    },
  }),
  defaultOptions: {
    queries: {
      retry: 2,
      onError: () => {
        errorToast()
      },
    },
    mutations: {
      onError: () => {
        errorToast()
      },
    },
  },
})

export const QueryProvider: FC<PropsWithChildren> = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
)
