import type { PreloadedState } from 'redux'
import type { RootState } from 'redux/reducers/state'
import { configureStore } from 'redux/store'

type Store = ReturnType<typeof configureStore>
export type InitialState = PreloadedState<RootState>

/**
 * @deprecated It's a really nasty solution. We shouldn't keep Redux store reference outside of Redux realm
 */
let _store: Store

/**
 * @description The function to initiate Redux store.
 * It shouldn't be use to access the store.
 * If you need to get a reference to the store, please use 'react-redux'.useStore hook
 */
export function store(initialState?: InitialState, force?: true): Store {
  if (!_store || force) {
    _store = configureStore(initialState)
  }
  return _store
}

type WaitForCheckFn = (store: RootState) => boolean
type WaitForExecuteFn = (store: RootState) => void
export function waitFor(checkCb: WaitForCheckFn, cb: WaitForExecuteFn) {
  const unsubscribe = _store.subscribe(() => {
    const state = _store.getState()
    if (checkCb(state)) {
      unsubscribe()
      cb(state)
    }
  })
}
