import { FC, PropsWithChildren } from 'react'
import { Typography } from '@g1/components'
import clsx from 'classnames'

import Styles from './HeaderLink.module.scss'

type HeaderButtonProps = PropsWithChildren & {
  active?: boolean
  onClick?: () => void
}

export const HeaderButton: FC<HeaderButtonProps> = ({
  children,
  active,
  onClick,
}) => (
  <Typography
    variant="body2"
    component="a"
    className={clsx(Styles.tabItem, {
      [Styles.active]: active,
    })}
    onClick={onClick}
  >
    {children}
  </Typography>
)
