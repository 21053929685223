import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import {
  changedSelectedAccount,
  changingSelectedAccount,
} from 'redux/actions/user'
import { AccountInfo } from 'redux/models'
import { getSelectedCounterparty } from 'redux/selectors/selectGalaxyUser'

import {
  getG1Accounts,
  isCPOAccount,
  isG1GotcAccount,
  isMarginTradingAccount,
  isTradingAccount,
} from 'utils/accounts'
import { HeaderLink } from '../HeaderLink'
import { TopMenuDropdown, TopMenuDropdownItem } from '../TopMenuDropdown'

import Styles from './TopMenu.module.scss'

export type TopMenu = {
  activeItem: string
}

const getAccountPageLink = (account: AccountInfo) =>
  isMarginTradingAccount(account) ? '/g1/margin' : '/g1/accounts'

export const TopMenu: FC<TopMenu> = ({ activeItem }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const selectedCounterparty = useSelector(getSelectedCounterparty)
  const isActive = (...paths: string[]) => paths.indexOf(activeItem) > -1

  const g1Accounts = getG1Accounts(selectedCounterparty?.accountInfo).map(
    (account, index) => ({ ...account, index }),
  )
  const cpoAccounts = g1Accounts.filter(isCPOAccount)
  const lendingAccounts = g1Accounts.filter(isG1GotcAccount)
  const otherAccounts = g1Accounts.filter(
    account => !isCPOAccount(account) && !isG1GotcAccount(account),
  )

  const handleAccountChange =
    (account: AccountInfo, url: string) => async () => {
      dispatch(changingSelectedAccount(account))
      await router.push(url)
      dispatch(changedSelectedAccount(account))
    }

  return (
    <div className={Styles.centerItemsContainer}>
      {selectedCounterparty.accountInfo.some(isCPOAccount) ||
      selectedCounterparty.accountInfo.some(isTradingAccount) ? (
        <HeaderLink href="/g1" active={isActive('/', '/g1')}>
          Trade
        </HeaderLink>
      ) : null}
      {otherAccounts.length > 0 && (
        <TopMenuDropdown
          active={isActive(
            '/g1/accounts',
            '/g1/margin',
            '/g1/margin/[[...margintradingslug]]',
          )}
          onClick={handleAccountChange(
            otherAccounts[0],
            getAccountPageLink(otherAccounts[0]),
          )}
          text="Accounts"
        >
          {otherAccounts.map(account => (
            <TopMenuDropdownItem
              key={account.accountId}
              title={account.accountName}
              description={account.accountTypeName}
              onClick={handleAccountChange(
                account,
                getAccountPageLink(account),
              )}
            />
          ))}
        </TopMenuDropdown>
      )}
      {lendingAccounts.length > 0 && (
        <TopMenuDropdown
          active={isActive('/g1/lending', '/g1/lending/[[...lendingslug]]')}
          onClick={handleAccountChange(lendingAccounts[0], '/g1/lending')}
          text="Lending"
        >
          {lendingAccounts.map(account => (
            <TopMenuDropdownItem
              key={account.accountId}
              title={account.accountName}
              description={account.accountTypeName}
              onClick={handleAccountChange(account, '/g1/lending')}
            />
          ))}
        </TopMenuDropdown>
      )}
      {cpoAccounts.length > 0 && (
        <TopMenuDropdown
          active={isActive('/g1/funds')}
          onClick={handleAccountChange(cpoAccounts[0], '/g1/funds')}
          text="Funds"
        >
          {cpoAccounts.map(account => (
            <TopMenuDropdownItem
              key={account.accountId}
              title={account.accountName}
              description={account.accountTypeName}
              onClick={handleAccountChange(account, '/g1/funds')}
            />
          ))}
        </TopMenuDropdown>
      )}
    </div>
  )
}
