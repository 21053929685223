import clsx from 'classnames'
import Link from 'next/link'

import Styles from './HeaderLink.module.scss'

export const HeaderLink = ({ children, active, href, ...rest }) => (
  <Link
    href={href}
    as={href}
    className={clsx(Styles.tabItem, {
      [Styles.active]: active,
    })}
    {...rest}
  >
    {children}
  </Link>
)
