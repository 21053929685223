import { Children, ComponentProps, FC } from 'react'
import { MenuItem, Typography, color } from '@g1/components'

import Styles from './TopMenuDropdown.module.scss'

type TopMenuDropdownItemProps = ComponentProps<typeof MenuItem> & {
  title?: string
  description?: string
}

export const TopMenuDropdownItem: FC<TopMenuDropdownItemProps> = ({
  children,
  title,
  description,
  ...rest
}) => (
  <MenuItem className={Styles.accountsMenuItem} {...rest}>
    {Children.count(children) > 0 ? (
      children
    ) : (
      <>
        <Typography variant="body2">{title}</Typography>
        <Typography variant="metadata" color={color.galaxyBlack[400]}>
          {description}
        </Typography>
      </>
    )}
  </MenuItem>
)
