import Router from 'next/router'
import { AccountInfo, CounterpartyDetails } from 'redux/models'
import { dispatch } from 'utils/dispatch'

export enum Type {
  OKTA_USER_CHANGED = 'OKTA_USER_CHANGED',
  AFTER_OKTA_USER_CHANGED = 'AFTER_OKTA_USER_CHANGED',
  GALAXY_USER_CHANGED = 'GALAXY_USER_CHANGED',
  STRATEGIES_DATA_CHANGED = 'STRATEGIES_DATA_CHANGED',
  SELECTED_STRATEGY_CHANGED = 'SELECTED_STRATEGY_CHANGED',
  SELECTED_STRATEGY_CHANGED_AFTER = 'SELECTED_STRATEGY_CHANGED_AFTER',
  USER_SIGNING_OUT = 'USER_SIGNING_OUT',
  SELECTED_COUNTERPARTY_CHANGING = 'SELECTED_COUNTERPARTY_CHANGING',
  SELECTED_COUNTERPARTY_CHANGED = 'SELECTED_COUNTERPARTY_CHANGED',
  SELECTED_ACCOUNT_CHANGING = 'SELECTED_ACCOUNT_CHANGING',
  SELECTED_ACCOUNT_CHANGED = 'SELECTED_ACCOUNT_CHANGED',
}

export type ChangeSelectedCounterpartyPayload = {
  counterparty: CounterpartyDetails
}

export const changeSelectedCounterparty = async (
  counterparty: CounterpartyDetails,
) => {
  dispatch({
    type: Type.SELECTED_COUNTERPARTY_CHANGING,
    payload: { counterparty },
  })
  await Router.push('/g1')
  dispatch({
    type: Type.SELECTED_COUNTERPARTY_CHANGED,
    payload: { counterparty },
  })
}

export type ChangeSelectedAccountPayload = {
  account: AccountInfo
}

export const changingSelectedAccount = (account: AccountInfo) => ({
  type: Type.SELECTED_ACCOUNT_CHANGING,
  payload: { account },
})

export const changedSelectedAccount = (account: AccountInfo) => ({
  type: Type.SELECTED_ACCOUNT_CHANGED,
  payload: { account },
})
