import { FC, MouseEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  CheckIcon,
  ChevronDownIcon,
  LogoutIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@g1/components'
import cls from 'classnames'
import Link from 'next/link'
import { get } from 'Gatekeeper'
import { changeSelectedCounterparty } from 'redux/actions/user'
import { CounterpartyDetails } from 'redux/models'
import { selectG1IsAdmin } from 'redux/selectors/selectG1IsAdmin'
import {
  getSelectedCounterparty,
  selectGalaxyUser,
} from 'redux/selectors/selectGalaxyUser'
import { isCPOAccount, isG1GotcAccount } from 'utils/accounts'
import { singleLogout } from 'utils/auth'
import { BankIcon } from './BankIcon'

import Styles from './PersonnelMenu.module.scss'

export const PersonnelMenu: FC = () => {
  const user = useSelector(selectGalaxyUser('g1'))
  const selectedCounterparty = useSelector(getSelectedCounterparty)
  const hasOnlyCpoAccounts = selectedCounterparty?.accountInfo.every(account =>
    isCPOAccount(account),
  )
  const hasOnlyG1GotcAccounts = selectedCounterparty?.accountInfo.every(
    account =>
      isG1GotcAccount(account) ||
      (!get('g1_show_cpo_address_book') && isCPOAccount(account)),
  )
  const isAdmin = useSelector(selectG1IsAdmin(selectedCounterparty.entityId))
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const entityClickHandler = (entity: CounterpartyDetails) => async () => {
    handleClose()
    await changeSelectedCounterparty(entity)
  }

  return (
    <div>
      <Button variant="text" onClick={handleClick} className={Styles.user}>
        <div className={Styles.userNameContainer}>
          <Tooltip
            className={Styles.tooltipText}
            title={user.firstName}
            disableInteractive
          >
            <Typography
              variant="body2"
              className={cls(Styles.userName, Styles.textElipsis)}
              component={'p'}
            >
              {user.firstName}
            </Typography>
          </Tooltip>
          <Tooltip
            className={Styles.tooltipText}
            title={selectedCounterparty.entityName}
            disableInteractive
          >
            <Typography
              variant="metadata"
              className={cls(Styles.entityName, Styles.textElipsis)}
              component="p"
            >
              {selectedCounterparty.entityName}
            </Typography>
          </Tooltip>
        </div>
        <ChevronDownIcon />
      </Button>
      {open && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
            className: Styles.personnelMenu,
          }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          <MenuItem>
            <Link href="/settings">
              <Typography variant="body2">Settings</Typography>
            </Link>
          </MenuItem>
          {!hasOnlyG1GotcAccounts && (
            <MenuItem>
              <Link href="/settings/addresses">
                <Typography variant="body2">Address Book</Typography>
              </Link>
            </MenuItem>
          )}
          {!hasOnlyCpoAccounts && isAdmin && (
            <MenuItem>
              <Link href="/settings/users">
                <Typography variant="body2">User Management</Typography>
              </Link>
            </MenuItem>
          )}
          <MenuItem>
            <Link href={'/settings/resources'}>
              <Typography variant="body2">Resources</Typography>
            </Link>
          </MenuItem>
          <MenuItem
            className={cls(
              Styles.grayLine,
              Styles.bgGalaxyBlack700,
              Styles.entityTitle,
            )}
            disabled
          >
            <Typography variant="body2">Entity</Typography>
          </MenuItem>
          {user?.counterPartyDetails?.map(entity => (
            <MenuItem
              key={entity.entityId}
              className={cls(
                Styles.entityItem,
                Styles.textGalaxyBlack400,
                Styles.bgGalaxyBlack700,
              )}
              onClick={entityClickHandler(entity)}
            >
              <div
                className={cls(
                  Styles.entityNameContainer,
                  selectedCounterparty?.entityId === entity.entityId
                    ? Styles.textGalaxyBlack100
                    : Styles.textGalaxyBlack400,
                )}
              >
                <BankIcon size={20} />
                <Tooltip
                  className={Styles.tooltipText}
                  title={entity.entityName}
                  disableInteractive
                >
                  <Typography
                    variant="label"
                    className={cls(Styles.entityName, Styles.textElipsis)}
                    component="p"
                  >
                    {entity.entityName}
                  </Typography>
                </Tooltip>
              </div>
              {selectedCounterparty?.entityId === entity.entityId && (
                <CheckIcon className={Styles.textGalaxyTeal300} />
              )}
            </MenuItem>
          ))}
          <MenuItem
            className={cls(Styles.grayLine, Styles.bgGalaxyBlack700)}
            onClick={singleLogout}
          >
            <div className={Styles.signOutItem}>
              <Typography variant="body2">Sign Out</Typography>
              <LogoutIcon />
            </div>
          </MenuItem>
        </Menu>
      )}
    </div>
  )
}
