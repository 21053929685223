import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import router from 'next/router'
import {
  changedSelectedAccount,
  changingSelectedAccount,
} from 'redux/actions/user'
import { getSelectedCounterparty } from 'redux/selectors/selectGalaxyUser'

const extractAccountIndex = (path: string) => {
  // ex path:
  // `/g1/${marginTrading ? 'margin' : 'lending'}/${accountIndex}/${loanDetails}/${noSpacesLoanName}` + (tabPath ? `/${tabPath}` : '')
  const segments = path.replace('/g1', 'g1').split('/')

  if (segments.length >= 3) {
    return parseInt(segments[2], 10)
  }
}

export const useLendingMarginNavigate = () => {
  const dispatch = useDispatch()
  const accountInfo = useSelector(getSelectedCounterparty)?.accountInfo

  const galaxyNavigate = useCallback(
    async (path: string) => {
      const accountIndex = extractAccountIndex(path)
      const account = accountInfo[accountIndex]
      if (account) {
        dispatch(changingSelectedAccount(account))
      }

      await router.push(path, undefined, {
        shallow: false,
      })

      if (account) {
        dispatch(changedSelectedAccount(account))
      }
    },
    [accountInfo, dispatch],
  )

  return galaxyNavigate
}
