import { selectGalaxyUser } from 'redux/selectors/selectGalaxyUser'
import { registerSaver } from 'redux/store/utils'
import { isG1 } from 'utils/envUtils'
import { isClientPortal } from 'utils/isClientPortal'

export const registerGalaxyUserSavers = () => {
  if (!isClientPortal) {
    registerSaver(state => {
      const internalUser = selectGalaxyUser('internal')(state)

      return {
        GalaxyUser: {
          data: {
            selectedStrategy: internalUser?.selectedStrategy ?? null,
            strategies: internalUser?.strategies ?? null,
          },
        },
      }
    })
  } else if (isG1()) {
    registerSaver(state => {
      const g1User = selectGalaxyUser('g1')(state)

      return {
        GalaxyUser: {
          data: {
            selectedCounterparty: g1User?.selectedCounterparty ?? null,
            selectedAccount: g1User?.selectedAccount ?? null,
          },
        },
      }
    })
  }
}
