import {
  AccountInfo,
  CounterpartyDetails,
  G1GalaxyUserData,
  InternalGalaxyUserData,
  Strategy,
} from 'redux/models'
import { selectGalaxyUser } from 'redux/selectors/selectGalaxyUser'
import { isG1 } from 'utils/envUtils'
import { getState } from 'utils/getState'
import { isClientPortal } from 'utils/isClientPortal'

export function isInternalUser(data: unknown): data is InternalGalaxyUserData {
  return !isClientPortal
}

export function isG1User(data: unknown): data is G1GalaxyUserData {
  return isG1()
}

export const getSelectedCounterparty = (
  stateUser: G1GalaxyUserData,
  draftUser: G1GalaxyUserData,
) => {
  return (
    draftUser.counterPartyDetails.find(
      counterparty =>
        counterparty.entityId === stateUser.selectedCounterparty?.entityId,
    ) ?? draftUser.counterPartyDetails?.[0]
  )
}

export const getSelectedAccount = (
  selectedCounterparty: CounterpartyDetails,
  selectedAccount?: AccountInfo,
) => {
  return (
    selectedCounterparty.accountInfo.find(
      account => account.accountId === selectedAccount?.accountId,
    ) ?? selectedCounterparty.accountInfo[0]
  )
}

/**
 * Parses the strategy payload to a tree-like structure by adding
 * - subStrategies to their parent
 * - parentId to the subStrategy
 *
 *  **NOTE**: This function mutates the content of the original array
 *
 * @param strategies original flat strategy array
 * @returns Tree-like structure of strategies
 */
export function parseStrategyPayload(strategies: Strategy[]) {
  if (!strategies) {
    return []
  }

  const strategyHierarchy: Strategy[] = strategies.slice()

  for (let i = 0; i < strategyHierarchy.length; i++) {
    const strategy = strategyHierarchy[i]
    /**
     * Strategy comes with a special syntax string in `idHierarchy`.
     * The string comes in the following format: `XX.YY`.
     * Example
     * - idHierarchy: '17'    <- it's a parent strategy
     * - idHierarchy: '17.24' <- it's a subStrategy
     * The parent id is always the first one
     */
    const hierarchyIds = strategy.idHierarchy.split('.')

    if (hierarchyIds.length > 1) {
      const [parentId] = hierarchyIds.map(id => parseInt(id))
      const strategyParent = strategyHierarchy.find(({ id }) => id === parentId)

      if (strategyParent) {
        strategyParent.subStrategies ??= []
        strategyParent.subStrategies.push(
          // Extends the subStrategy with the parentId.
          // Object.assign is used to keep the original object reference.
          Object.assign(strategy, { parentId: strategyParent.id }),
        )
      }
      /**
       * remove the already added subStrategies that are in the initial array
       * to evade duplicates
       */
      strategyHierarchy.splice(i, 1)
      // decrease loop index since we removed a record from the array while looping
      i--
    }
  }
  return strategyHierarchy
}

export function isStrategySubstrategy(
  strategyId: number,
  compareId: number | null = null,
) {
  let ret = false
  if (strategyId === compareId) {
    ret = true
  } else {
    const user = selectGalaxyUser('internal')(getState())
    if (user?.strategies) {
      const strat = user.strategies.find(
        strat => strat.subStrategies?.find(strat => strat.id === strategyId),
      )
      ret = compareId === null ? !!strat : strat && strat.id === compareId
    }
  }
  return ret
}

export function getStrategyParent(strategyId: number) {
  const user = selectGalaxyUser('internal')(getState())
  if (user && user.strategies) {
    const strat = user.strategies.find(
      strat =>
        strat.id === strategyId ||
        (strat.subStrategies &&
          strat.subStrategies.find(strat => strat.id === strategyId)),
    )
    if (strat) {
      return strat
    }
  }
}
