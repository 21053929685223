import { FC } from 'react'
import { EmptyState } from '@g1/components-internal'
import AuthScreen from '@design-library/AuthScreen'
import { SystemError, SystemErrorProps } from 'components/SystemError'
import type { UseAuthHookResult } from 'utils/authOkta'

import Styles from './styles.module.css'

type Props = {
  state: Exclude<UseAuthHookResult['authorizationState'], 'authorized'>
}

const stateToConfig: Record<Props['state'], SystemErrorProps> = {
  authorizing: {
    title: 'Authorizing',
    message: 'Please wait while the system tries to authorize your access.',
  },
  unauthorized: {
    title: 'Authorization Failed',
    message:
      'Identity Service was not able to provide the records related to your account. Please contact Tech Support to resolve this issue.',
  },
  error: {
    title: 'Authorization Critical Issue',
    message: (
      <>
        The application is unable to authorize the account due to
        <span className={Styles.highlight}>Identity Service</span> issue.
        <br />
        Please contact Tech Support.
      </>
    ),
  },
}

export const InternalAuthorization: FC<Props> = ({ state }) => {
  const { message, title } = stateToConfig[state]

  if (state === 'authorizing') {
    return (
      <AuthScreen
        title={title}
        message={message}
        image={<EmptyState heading={null} />}
      />
    )
  }

  return <SystemError title={title} message={message} />
}
