import deepEqual from 'react-fast-compare'
import * as Sentry from '@sentry/browser'
import { produce } from 'immer'
import { handleActions } from 'redux-actions'
import {
  ChangeSelectedAccountPayload,
  ChangeSelectedCounterpartyPayload,
  Type,
} from 'redux/actions/user'
import { GalaxyUserModel } from 'redux/models'
import { dispatch } from 'utils/dispatch'
import { REDUX_INITIAL_SET } from '../../store/localStorage'
import { RootState } from '../state'
import { registerGalaxyUserSavers } from './savers'
import {
  getSelectedAccount,
  getSelectedCounterparty,
  isG1User,
  isInternalUser,
  parseStrategyPayload,
} from './utils'

registerGalaxyUserSavers()

const initialState: GalaxyUserModel = {
  data: undefined,
  localStorageLoaded: false,
}

export const GalaxyUserReducer = handleActions<
  GalaxyUserModel,
  GalaxyUserModel['data']
>(
  {
    [REDUX_INITIAL_SET]: (state: GalaxyUserModel, { payload }) => {
      return produce(state, draft => {
        draft.data ??= {} as GalaxyUserModel['data']

        try {
          const cachedUser = (payload as unknown as RootState)?.GalaxyUser?.data

          if (!cachedUser) {
            return
          }

          if (isG1User(cachedUser) && isG1User(draft.data)) {
            draft.data.selectedCounterparty = cachedUser.selectedCounterparty
            draft.data.selectedAccount = cachedUser.selectedAccount
          } else if (isInternalUser(cachedUser) && isInternalUser(draft.data)) {
            draft.data.selectedStrategy = cachedUser.selectedStrategy
            draft.data.strategies = cachedUser.strategies
          }
        } finally {
          draft.localStorageLoaded = true
        }
      })
    },
    [Type.GALAXY_USER_CHANGED]: (state: GalaxyUserModel, { payload }) => {
      return produce(state, draft => {
        draft.data = payload
        Sentry.configureScope(scope => {
          scope.setUser({
            ...payload,
            id: ('id' in payload ? payload.id : payload.userId)?.toString(),
          })
        })

        if (isG1User(draft.data) && isG1User(state.data)) {
          draft.data.selectedCounterparty = getSelectedCounterparty(
            state.data,
            draft.data,
          )
          draft.data.selectedCounterpartyState = 'changed'

          const selectedAccount = state.data.selectedAccount
          draft.data.selectedAccount = getSelectedAccount(
            draft.data.selectedCounterparty,
            selectedAccount,
          )
          draft.data.selectedAccountState = 'changed'
        }

        if (
          !isInternalUser(draft.data) ||
          !isInternalUser(state.data) ||
          !isInternalUser(payload)
        ) {
          return
        }

        if (state.data) {
          draft.data.selectedStrategy = state.data.selectedStrategy
        }

        const currentStrategies = draft.data.strategies
        const newStrategies = payload.strategies
        draft.data.strategies = parseStrategyPayload(payload.strategies)

        if (!deepEqual(currentStrategies, draft.data.strategies)) {
          if (draft.data.selectedStrategy) {
            const selectedId = draft.data.selectedStrategy.id
            const newSelectedStrategy = newStrategies.find(
              ({ id }) => id === selectedId,
            )
            draft.data.selectedStrategy = newSelectedStrategy
          }

          draft.data.selectedStrategy ??= newStrategies[0]
        }

        dispatch({
          type: Type.SELECTED_STRATEGY_CHANGED_AFTER,
          payload: {},
        })
      })
    },
    [Type.SELECTED_STRATEGY_CHANGED]: (state: GalaxyUserModel, { payload }) => {
      return produce(state, draft => {
        if (
          !isInternalUser(draft.data) ||
          !isInternalUser(state.data) ||
          !isInternalUser(payload)
        ) {
          return
        }
        const prevSelectedStrategy = state.data?.selectedStrategy
        const newSelectedStrategy = payload.selectedStrategy
        // runs when user interacts with the strategy button to select one
        if (
          newSelectedStrategy !== undefined &&
          prevSelectedStrategy !== newSelectedStrategy
        ) {
          draft.data.selectedStrategy = newSelectedStrategy
        }
        dispatch({
          type: Type.SELECTED_STRATEGY_CHANGED_AFTER,
          payload: {},
        })
      })
    },
    [Type.SELECTED_COUNTERPARTY_CHANGING]: (state: GalaxyUserModel) =>
      produce(state, draft => {
        if (!isG1User(draft.data)) {
          return
        }

        draft.data.selectedCounterpartyState = 'changing'
      }),
    [Type.SELECTED_COUNTERPARTY_CHANGED]: (
      state: GalaxyUserModel,
      { payload },
    ) =>
      produce(state, draft => {
        if (!isG1User(draft.data)) {
          return
        }

        const entityChangedPayload =
          payload as unknown as ChangeSelectedCounterpartyPayload
        draft.data.selectedCounterpartyState = 'changed'
        draft.data.selectedCounterparty = entityChangedPayload.counterparty
        draft.data.selectedAccount =
          entityChangedPayload.counterparty.accountInfo[0]
      }),
    [Type.SELECTED_ACCOUNT_CHANGING]: (state: GalaxyUserModel) =>
      produce(state, draft => {
        if (!isG1User(draft.data)) {
          return
        }

        draft.data.selectedAccountState = 'changing'
      }),
    [Type.SELECTED_ACCOUNT_CHANGED]: (state: GalaxyUserModel, { payload }) =>
      produce(state, draft => {
        if (!isG1User(draft.data)) {
          return
        }

        const accountChangePayload =
          payload as unknown as ChangeSelectedAccountPayload
        draft.data.selectedAccount = accountChangePayload.account
        draft.data.selectedAccountState = 'changed'
      }),
  },
  initialState,
)
