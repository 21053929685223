import { FC } from 'react'
import { GalaxyOneProvider, LoadingIndicator } from '@g1/components'

import Styles from './RouteLoading.module.scss'

export const RouteLoading: FC = () => (
  <div className={Styles.routeLoadingContainer}>
    <GalaxyOneProvider theme="dark">
      <LoadingIndicator />
    </GalaxyOneProvider>
  </div>
)
