import 'react-dates/initialize'

import React, { FC, memo } from 'react'

import { Provider } from 'react-redux'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { useRouter } from 'next/router'
import { useIsFirstRender } from 'hooks/useIsFirstRender'
import { store } from 'store'
import { isBrowser } from 'utils/isBrowser'
import oktaAuth from 'utils/OktaConfig'
import { ThemeProvider, appTheme } from 'utils/styled'
import { GalaxyAppHead } from './components/GalaxyAppHead'
import { QueryProvider } from './components/ReactQuery'
import { SecuredContent } from './components/SecuredContent'

type GalaxyAppBaseProps = {
  className: string
  appHeader: React.ReactElement
  onPageRender(): React.ReactElement
}

const GalaxyAppBase: FC<GalaxyAppBaseProps> = ({
  className,
  appHeader,
  onPageRender,
}) => {
  const isFirstRender = useIsFirstRender(true)
  const router = useRouter()
  const isImplicitCallback = router.pathname === '/implicit/callback'

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    router.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  const appPage: React.ReactElement =
    isBrowser && !isFirstRender ? (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        {isImplicitCallback ? (
          onPageRender()
        ) : (
          <SecuredContent appHeader={appHeader} page={onPageRender()} />
        )}
      </Security>
    ) : null

  return (
    <>
      <GalaxyAppHead />
      <noscript>You need to enable JavaScript to run this app.</noscript>
      <div id="root" suppressHydrationWarning className={className}>
        <ThemeProvider theme={appTheme}>
          <QueryProvider>
            <Provider store={store()}>{appPage}</Provider>
          </QueryProvider>
        </ThemeProvider>
      </div>
    </>
  )
}

export default memo(GalaxyAppBase)
