import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import useAuthUser from 'hooks/useAuthUser'
import { RouteLoading } from './RouteLoading'

export const SelectedCounterpartyController: FC<PropsWithChildren> = ({
  children,
}) => {
  const router = useRouter()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const start = (_: string, opts: { shallow: boolean }) => {
      if (opts?.shallow) {
        return
      }

      setLoading(true)
    }

    const end = () => setLoading(false)

    router.events.on('routeChangeStart', start)
    router.events.on('routeChangeComplete', end)
    router.events.on('routeChangeError', end)

    return () => {
      router.events.off('routeChangeStart', start)
      router.events.off('routeChangeComplete', end)
      router.events.off('routeChangeError', end)
    }
  }, [router])

  const user = useAuthUser('g1')
  return user?.selectedCounterpartyState !== 'changed' || loading ? (
    <RouteLoading />
  ) : (
    <>{children}</>
  )
}
